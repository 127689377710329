import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

const app = createApp(App);

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

function setFavicon(url) {
  let link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = url;
  document.getElementsByTagName("head")[0].appendChild(link);
}

setFavicon("https://cdn-icons-png.flaticon.com/512/2797/2797775.png");

app.use(router).mount("#app");
