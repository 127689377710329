<template>
  <div class="wrapper">
    <div>
      <h1>V<small class="small">ojta</small></h1>
      <div class="counter">{{ mainCounter }}</div>
      <div class="btns" v-if="isAdmin">
        <button @click="incrementMain" type="button">Increase</button>
      </div>
    </div>

    <div>
      <h1>L<small class="small">ukáš</small></h1>
      <div class="counter">{{ secondaryCounter }}</div>
      <div class="btns" v-if="isEditor">
        <button @click="incrementSecondary" type="button">Increase</button>
      </div>
    </div>

    <div>
      <h1>W<small class="small">ojta</small></h1>
      <div class="counter">{{ tertiaryCounter }}</div>
      <div class="btns" v-if="isNoob">
        <button @click="incrementTertiary" type="button">Increase</button>
      </div>
    </div>
  </div>

  <div class="stats">
    <canvas id="statsChart"></canvas>
  </div>

  <small style="color: #666666;">Counter se resetuje každé pondělí</small> <br>
  <small style="color: #666666;">Poslední aktualizace: {{ lastUpdate }}</small>

<HistoryComponent></HistoryComponent>
</template>

<script>

import { ref, onMounted } from "vue";
import HistoryComponent from './HistoryComponent.vue';
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip
} from "chart.js";
import { doc, setDoc, updateDoc, onSnapshot, arrayUnion } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFirestore, getDoc } from "firebase/firestore";

Chart.register(
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip
);

export default {
  components : {
    HistoryComponent
  },
  setup() {
    const mainCounter = ref(0);
    const secondaryCounter = ref(0);
    const tertiaryCounter = ref(0);

    const mainHistory = ref([]);
    const secondaryHistory = ref([]);
    const tertiaryHistory = ref([]);

    const lastUpdate = ref("");
    const historyData = ref([]);

    const isAdmin = ref(null);
    const isEditor = ref(null);
    const isNoob = ref(null);

    let chartInstance = null;


    const loadHistoryData = async () => {
      const historyRef = doc(db, "counters", "history");
      const docSnap = await getDoc(historyRef);
      if (docSnap.exists()) {
        historyData.value = docSnap.data().weeks || [];
      } else {
        await setDoc(historyRef, { weeks: [] });
      }
    };

    const updateLastUpdate = async () => {
      const docRef = doc(db, "counters", "lastUpdate");
      const now = new Date().toISOString();
      await setDoc(docRef, { timestamp: now });
      lastUpdate.value = new Date(now).toLocaleString();
    };

    const loadLastUpdate = async () => {
      const docRef = doc(db, "counters", "lastUpdate");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        lastUpdate.value = new Date(docSnap.data().timestamp).toLocaleString();
      } else {
        await updateLastUpdate();
      }
    };

    const loadMainCounter = () => {
      const docRef = doc(db, "counters", "main");
      onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          mainCounter.value = data.value;
          mainHistory.value = data.history || [];
          renderChart();
        } else {
          setDoc(docRef, { value: 0, history: [] });
        }
      });
    };

    const loadSecondaryCounter = () => {
      const docRef = doc(db, "counters", "secondary");
      onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          secondaryCounter.value = data.value;
          secondaryHistory.value = data.history || [];
          renderChart();
        } else {
          setDoc(docRef, { value: 0, history: [] });
        }
      });
    };

    const loadTertiaryCounter = () => {
      const docRef = doc(db, "counters", "tertiary");
      onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          tertiaryCounter.value = data.value;
          tertiaryHistory.value = Object.values(data.history || []); // Převod objektu na pole
          renderChart();
        } else {
          setDoc(docRef, { value: 0, history: [] });
        }
      });
    };

    const incrementMain = async () => {
      const docRef = doc(db, "counters", "main");
      const newValue = mainCounter.value + 1;
      await updateDoc(docRef, {
        value: newValue,
        history: arrayUnion({ timestamp: new Date().toISOString(), value: newValue }),
      });
      await updateLastUpdate();
      renderChart();
    };

    const decrementMain = async () => {
      const docRef = doc(db, "counters", "main");
      const newValue = mainCounter.value - 1;
      await updateDoc(docRef, {
        value: newValue,
        history: arrayUnion({ timestamp: new Date().toISOString(), value: newValue }),
      });
      await updateLastUpdate();
      renderChart();
    };

    const incrementSecondary = async () => {
      const docRef = doc(db, "counters", "secondary");
      const newValue = secondaryCounter.value + 1;
      await updateDoc(docRef, {
        value: newValue,
        history: arrayUnion({ timestamp: new Date().toISOString(), value: newValue }),
      });
      await updateLastUpdate();
      renderChart();
    };

    const decrementSecondary = async () => {
      const docRef = doc(db, "counters", "secondary");
      const newValue = secondaryCounter.value - 1;
      await updateDoc(docRef, {
        value: newValue,
        history: arrayUnion({ timestamp: new Date().toISOString(), value: newValue }),
      });
      await updateLastUpdate();
      renderChart();
    };

    const incrementTertiary = async () => {
      const docRef = doc(db, "counters", "tertiary");
      const newValue = tertiaryCounter.value + 1;
      await updateDoc(docRef, {
        value: newValue,
        history: arrayUnion({ timestamp: new Date().toISOString(), value: newValue }),
      });
      await updateLastUpdate();
      renderChart();
    };

    const decrementTertiary = async () => {
      const docRef = doc(db, "counters", "tertiary");
      const newValue = tertiaryCounter.value - 1;
      await updateDoc(docRef, {
        value: newValue,
        history: arrayUnion({ timestamp: new Date().toISOString(), value: newValue }),
      });
      await updateLastUpdate();
      renderChart();
    };

    const renderChart = () => {
      const timestamps = [...new Set([
        ...mainHistory.value.map((entry) => entry.timestamp),
        ...secondaryHistory.value.map((entry) => entry.timestamp),
        ...tertiaryHistory.value.map((entry) => entry.timestamp),
      ])].sort();

      const fillData = (history, timestamps) => {
        let lastValue = 0; 
        return timestamps.map((timestamp) => {
          const entry = history.find((entry) => entry.timestamp === timestamp);
          if (entry) {
            lastValue = entry.value;
          }
          return lastValue; 
        });
      };

      const mainData = fillData(mainHistory.value, timestamps);
      const secondaryData = fillData(secondaryHistory.value, timestamps);
      const tertiaryData = fillData(tertiaryHistory.value, timestamps);

      const canvas = document.getElementById("statsChart");
      const ctx = canvas.getContext("2d");
      canvas.width = Math.min(window.innerWidth - 50, 800);
      canvas.height = 400; 

      if (chartInstance) {
        chartInstance.destroy();
      }

      chartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: timestamps.map((timestamp) => new Date(timestamp).toLocaleString()),
          datasets: [
            {
              label: "Vojta",
              data: mainData,
              borderColor: "#36a2eb",
              fill: false,
            },
            {
              label: "Lukáš",
              data: secondaryData,
              borderColor: "#ff6384",
              fill: false,
            },
            {
              label: "Wojta",
              data: tertiaryData,
              borderColor: "#696969",
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false, // Zajištění, že poměr stran bude přizpůsoben
          plugins: {
            title: {
              display: true,
              text: "Skibidi counter",
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Čas",
              },
            },
            y: {
              title: {
                display: true,
                text: "Hodnoty",
              },
              stepSize: 1,
              beginAtZero: true,
            },
          },
        },
      });
    };



    // Zjištění role uživatele - role jsou uloženy v databázi "users" a je to přiřazené k emailu účtu (uživatel je přístupný na getAuth().currentUser)
    const auth = getAuth();
    const db = getFirestore();

    const user = auth.currentUser;

    if (user) {
      const userDocRef = doc(db, "users", user.uid);

      getDoc(userDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();

            if (userData.role === "admin") {
              isAdmin.value = true;
              isEditor.value = true;
              isNoob.value = true;
            } else if (userData.role === "editor") {
              isAdmin.value = false;
              isEditor.value = true;
              isNoob.value = false;
            } else if (userData.role === "noob") {
              isAdmin.value = false;
              isEditor.value = false;
              isNoob.value = true;
            } else {
              // Výchozí nastavení práv, pokud role není rozpoznána
              isAdmin.value = false;
              isEditor.value = false;
              isNoob.value = false;
            }
          } else {
            console.error("No such document!");
          }
        })
        .catch((error) => {
          console.error("Error getting document:", error);
        });
    } else {
      console.error("No user is logged in.");
    }


    onMounted(() => {
      loadMainCounter();
      loadSecondaryCounter();
      loadTertiaryCounter();
      loadLastUpdate();
      loadHistoryData();
    });

    return {
      mainCounter,
      secondaryCounter,
      tertiaryCounter,
      isAdmin,
      isEditor,
      isNoob,
      incrementMain,
      decrementMain,
      incrementSecondary,
      decrementSecondary,
      incrementTertiary,
      decrementTertiary,
      lastUpdate,
      historyData,
    };
  },
};


</script>

<style>
body:not(:has(.wrapped-container)) {
  width: 100vw;
  height: 100svh;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  background-color: #242424;
  color: white;
  text-align: center;
}

@media screen and (min-width: 768px) {
  body {
    display: flex;
  }
}

.small {
  font-size: 0.5em;
}

.counter {
  font-size: 10rem;
  font-weight: bold;
  margin: 0;
}

.btns {
  margin-top: 20px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
  }
}

.stats {
  margin-top: 50px;
  text-align: center;
}

#statsChart {
  max-width: 800px;
  margin: 0 auto;
  display: none;
}

@media (min-width: 768px) {
  #statsChart {
    display: block;
  }
}


</style>